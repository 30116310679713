export default {
  methods: {
    async getList(server=false) {
      return this.$store.dispatch("getListTuCuenta",server);
    },
    async getListFromServer() {
      return await this.getList(true)
    },
  },
  async serverPrefetch() {
    return await this.getListFromServer();
  },
  async mounted() {
    if (!this.myList) {
      return await this.getList()
    }
    // TODO el list obtinguts a partir de Login fan entrar en aquest if i obtenir la llista dos cops
    // OPCIÓ 1 -> no obtenir la llista després del Login
    // OPCIÓ 2 -> Millorar aquest codi
    if (!this.myList.server) {
      await this.getList()
    }
  }
}