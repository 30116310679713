<template>
  <div>
    <main v-show="productReady">
      <div class="preview-nav">
        <div class="bb-container preview-nav__container">
          <div class="preview-nav__left">
            <a @click.prevent="close()">
              <div class="preview-nav__back">
                <img
                    style="display: none"
                    src="../../assets/img/ico/ico-arrow-back.svg"
                    loading="lazy"
                    alt="Volver"
                    class="preview-nav__arrow preview-nav__arrow--back"
                />
              </div>
            </a>
          </div>
          <div class="preview-nav__right">
            <a @click.prevent="close()">
              <div class="preview-nav__close">
                <img
                    src="../../assets/img/ico/ico-close.svg"
                    loading="lazy"
                    alt="Volver"
                    class="preview-nav__arrow preview-nav__close"
                />
              </div>
            </a>
          </div>
        </div>
      </div>

      <div class="bb-container">
        <div id="Fitxa" class="g-product pad-t-1">
          <div class="g-product__pictures">
            <no-ssr>
              <product-images :ready="productReady" :product="product"></product-images>
            </no-ssr>
          </div>
          <div class="g-product__summary">
            <product-details
                v-show="productReady" :product="product"
                source="Constructor"
                :isHero="isHero"
            ></product-details>
          </div>
        </div>

        <product-abstract :product="product"></product-abstract>

        <product-description :product="product"></product-description>
      </div>
    </main>
    <main v-show="!productReady">
      <ProductPageSkeleton></ProductPageSkeleton>
    </main>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import loginMixin from "../mixins/login.js";
import ProductImages from "../product/components/ProductImages";
import ProductDetails from "../product/components/ProductDetails";
import ProductAbstract from "../product/components/ProductAbstract";
import ProductDescription from "../product/components/ProductDescription";
import CanGetList from "../../Mixins/CanGetList";
import NoSSR from "vue-no-ssr";
import ProductPageSkeleton from "../../components/skeleton/ProductPageSkeleton";

export default {
  name: "ConstructorProductPage",
  components: {
    ProductImages,
    ProductDetails,
    ProductAbstract,
    ProductDescription,
    "no-ssr": NoSSR,
    ProductPageSkeleton,
  },
  metaInfo() {
    return {
      title: this.product.name + " | HelloBB",
    };
  },
  mixins: [loginMixin, CanGetList],
  data() {
    return {
      password: "",
      email: "",
      loading: false,
      emailEmpty: false,
      passwordEmpty: false,
      nameEmpty: false,
      valid: true,
      msg: "",
      error: null,
      productReady: false,
    };
  },
  computed: {
    ...mapGetters({
      product: "product",
      myList: "myList",
      listConstructor: "listConstructor",
    }),
    title() {
      if (this.product.metaInfo != null) {
        if (this.product.metaInfo.title === "") return this.product.brand;
        return this.product.metaInfo.title;
      } else {
        return this.product.brand;
      }
    },
    description() {
      if (this.product.metaInfo != null) {
        return this.product.metaInfo.description;
      } else {
        return this.product.brand;
      }
    },
    model() {
      return this.product.model.length > 80
        ? this.product.model.substring(0, 80) + "..."
        : this.product.model;
    },
    isHero() {
      let res = false;
      this.listConstructor?.initial_sections?.forEach((element) =>
        element.products?.forEach((product) => {
          if (parseInt(product.id) === parseInt(this.product.id) && product.hero)
            res = res || true;
        })
      );
      this.listConstructor?.sections?.forEach((element) =>
        element.products?.forEach((product) => {
          if (parseInt(product.id) === parseInt(this.product.id) && product.hero)
            res = res || true;
        })
      );
      return res;
    },
  },
  async serverPrefetch() {
    try {
      await this.getProduct();
    } catch (error) {
      if (error.response?.status === 404) {
        await this.$router.push({ name: "notfound" });
      }
      throw error;
    }
    this.productReady = true
  },
  async mounted() {
    await this.prepareProduct();
    //await this.getProduct();
    //if (this.$utils.isEmpty(this.listConstructor)) {
    //  await this.$store.dispatch("getListConstructor");
    //}
    let subCategory = "";
    let subCategory2 = "";
    if (this.product.subCategory1 != null)
      subCategory = ">" + this.product.subCategory1;
    if (this.product.subCategory2 != null)
      subCategory2 = ">" + this.product.subCategory2;

    window.mixpanel.track("Veure pàgina objecte", {
      "Item name": this.product.name,
      "Item Category": this.product.mainCategory + subCategory + subCategory2,
      "Num Retailers": this.product.productRetailers?.length,
      Rating: this.product.rating,
      Priority: this.product.priority,
    });
  },
  async beforeRouteUpdate(to, from, next) {
    await this.prepareProduct(to);
    next();
  },
  methods: {
    close() {
      // const product = {
      //   summary: "",
      //   whatLikeMost: "",
      //   manufacturerDescription: "",
      //   technicalDescription: "",
      //   productRetailers: [],
      //   minPrice: 0,
      //   maxPrice: 0,
      // };
      //this.$store.commit("setProduct", product);
      this.$router.back();
    },
    async prepareProduct(route = this.$route) {
      this.productReady = false;
      await this.getProductAtClientSide(route);
      this.productReady = true;
    },
    async getProductAtClientSide(route = this.$route) {
      if (parseInt(this.product.id) !== parseInt(route.params.id)) {
        try {
          await this.getProduct(route);
        } catch (error) {
          if (error.response?.status === 404) {
            await this.$router.push({ name: "notfound" });
          }
          throw error;
        }
      }
    },
    async getProduct(route = this.$route) {
      await this.$store.dispatch("getProduct", route.params.id);
    },
  },
};
</script>
